const LOCALE = "en-AU"

export const toCurrency = (value, numDecimals = 2) => {
  // eslint-disable-next-line no-undef
  const options = new Intl.NumberFormat(LOCALE, {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  })
  return options.format(value)
}

export const toPercentage = (value, numDecimals = 2) => {
  // eslint-disable-next-line no-undef
  const options = new Intl.NumberFormat(LOCALE, {
    style: "percent",
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  })
  return options.format(value)
}
