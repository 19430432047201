import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Heading, Box, FormControl, Input } from "insites-ui"
import { DollarSign, Info } from "react-feather"
import Slider from "react-input-slider"
import "../../styles/tools.css"
import seoLogo from "../../images/calculator.png"
import ReactTooltip from "react-tooltip"
import { PMT, calcIncome, NPER } from "../../components/tools/helpers"
import { toPercentage, toCurrency } from "../../components/tools/format"

const re = /^[0-9\b]+$/
const removeLeading = /^0+/

const InfoTooltip = ({ message, id }) => (
  <span className="tooltip-container">
    <span
      data-tip={message}
      data-for={`tooltip-${id}`}
      className="tooltip-icon"
    >
      <Info size={16} />
    </span>
    <ReactTooltip
      id={`tooltip-${id}`}
      effect="solid"
      className="tooltip-override"
      html={true}
    />
  </span>
)

const RentalYieldCalculator = () => {
  const [propertyPrice, setPropertyPrice] = useState(500000)
  const [rentalIncome, setRentalIncome] = useState(400)
  const [ratesUtilities, setRatesUtilites] = useState(0)
  const [insurance, setInsurance] = useState(0)
  const [strataBodyCorp, setStrataBodyCorp] = useState(0)
  const [management, setManagement] = useState(6)
  const [downpayment, setDownpayment] = useState(20)
  const [interestRate, setInterestRate] = useState(3)
  const [taxRate, setTaxRate] = useState(37)

  const [grossYield, setGrossYield] = useState(0)
  const [netYield, setNetYield] = useState(0)
  const [cashflow, setCashflow] = useState(0)
  const [income, setIncome] = useState(0)
  const [tax, setTax] = useState(0)

  useEffect(() => {
    const validPropertyPrice = propertyPrice === 0 ? 1 : propertyPrice
    const yearlyRent = rentalIncome * 52
    const expenses = -(
      ratesUtilities +
      strataBodyCorp +
      insurance +
      yearlyRent * (management / 100)
    )

    const irate = interestRate / 100 / 12
    const calculatedGrossYield = yearlyRent / validPropertyPrice
    setGrossYield(calculatedGrossYield)

    const netIncome = yearlyRent + expenses
    const calculatedNetYield = netIncome / validPropertyPrice
    setNetYield(calculatedNetYield)

    const loanValue = validPropertyPrice * (1 - downpayment / 100)

    const pmt = PMT(irate, NPER, loanValue)
    const yearlyPmt = pmt * 12
    const calculatedCashflow = netIncome + yearlyPmt
    setCashflow(calculatedCashflow)

    const calculatedIncome = netIncome + calcIncome(irate, NPER, loanValue)
    setIncome(calculatedIncome)

    const calculatedTax = (taxRate / 100) * calculatedIncome
    setTax(calculatedTax)
  }, [
    propertyPrice,
    rentalIncome,
    ratesUtilities,
    insurance,
    strataBodyCorp,
    management,
    downpayment,
    interestRate,
    taxRate,
  ])

  return (
    <Layout>
      <SEO
        title="Rental Yield Calculator"
        description="Simple net rental yield and capital gains calculator for an investment property with negative gearing, tax, cashflow and income functionality."
        image={seoLogo}
      />
      <section className="text-page-section tool-section">
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-lg-12 px-0"}>
              <Heading>Rental Yield Calculator</Heading>
              <Box
                backgroundColor="#ffffff"
                boxShadow="0 0.5rem 1rem rgba(0, 0, 0, 0.05)"
                borderRadius="0.5rem"
                padding="2rem"
              >
                <div className="row">
                  <div className="col-lg-8 tool-form">
                    <form>
                      <div className="row">
                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="propertyPrice">
                              Property Price
                            </FormControl.Label>
                            <Input
                              id="propertyPrice"
                              value={propertyPrice}
                              IconComponent={() => (
                                <DollarSign color="#c7c7c7" size={16} />
                              )}
                              onChange={e => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setPropertyPrice(
                                    Number(
                                      e.target.value.replace(removeLeading, "")
                                    )
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="rentalIncome">
                              Rental Income (weekly)
                            </FormControl.Label>
                            <Input
                              id="displayName"
                              value={rentalIncome}
                              IconComponent={() => (
                                <DollarSign color="#c7c7c7" size={16} />
                              )}
                              onChange={e => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setRentalIncome(
                                    Number(
                                      e.target.value.replace(removeLeading, "")
                                    )
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="ratesUtilities">
                              Rates/Utilities (yearly)
                            </FormControl.Label>
                            <Input
                              id="ratesUtilities"
                              value={ratesUtilities}
                              IconComponent={() => (
                                <DollarSign color="#c7c7c7" size={16} />
                              )}
                              onChange={e => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setRatesUtilites(
                                    Number(
                                      e.target.value.replace(removeLeading, "")
                                    )
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="insurance">
                              Insurance (yearly)
                            </FormControl.Label>
                            <Input
                              id="insurance"
                              value={insurance}
                              IconComponent={() => (
                                <DollarSign color="#c7c7c7" size={16} />
                              )}
                              onChange={e => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setInsurance(
                                    Number(
                                      e.target.value.replace(removeLeading, "")
                                    )
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="strataBodyCorp">
                              Strata/Body Coporate (yearly)
                            </FormControl.Label>
                            <Input
                              id="strataBodyCorporate"
                              value={strataBodyCorp}
                              IconComponent={() => (
                                <DollarSign color="#c7c7c7" size={16} />
                              )}
                              onChange={e => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  setStrataBodyCorp(
                                    Number(
                                      e.target.value.replace(removeLeading, "")
                                    )
                                  )
                                }
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="strataBodyCorp">
                              Management Fees (%)
                              <InfoTooltip
                                id="managementFees"
                                message="Percentage of rent paid to property manager (if applicable)."
                              />
                              <span className="float-right">{management}%</span>
                            </FormControl.Label>
                            <Slider
                              axis="x"
                              xstep={1}
                              xmin={4}
                              xmax={10}
                              x={management}
                              onChange={({ x }) => setManagement(Number(x))}
                              styles={{
                                track: {
                                  width: "100%",
                                },
                                active: {
                                  backgroundColor: "#0040ff",
                                },
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="strataBodyCorp">
                              Deposit (%)
                              <span className="float-right">
                                {downpayment}%
                              </span>
                            </FormControl.Label>
                            <Slider
                              axis="x"
                              xstep={5}
                              xmin={0}
                              xmax={100}
                              x={downpayment}
                              onChange={({ x }) => setDownpayment(Number(x))}
                              styles={{
                                track: {
                                  width: "100%",
                                },
                                active: {
                                  backgroundColor: "#0040ff",
                                },
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="strataBodyCorp">
                              Interest Rate (%)
                              <span className="float-right">
                                {interestRate.toFixed(2)}%
                              </span>
                            </FormControl.Label>
                            <Slider
                              axis="x"
                              xstep={0.25}
                              xmin={2}
                              xmax={5}
                              x={interestRate}
                              onChange={({ x }) => setInterestRate(Number(x))}
                              styles={{
                                track: {
                                  width: "100%",
                                },
                                active: {
                                  backgroundColor: "#0040ff",
                                },
                              }}
                            />
                          </FormControl>
                        </div>

                        <div className="col-lg-6 tool-field-input py-2">
                          <FormControl>
                            <FormControl.Label htmlFor="strataBodyCorp">
                              Tax Rate (%)
                              <InfoTooltip
                                id="taxRate"
                                message="Your marginal tax rate to calculate the tax benefit you will receive if you negatively gear, or tax you will pay if positively geared."
                              />
                              <span className="float-right">{taxRate}%</span>
                            </FormControl.Label>
                            <Slider
                              axis="x"
                              xstep={1}
                              xmin={0}
                              xmax={50}
                              x={taxRate}
                              onChange={({ x }) => setTaxRate(Number(x))}
                              styles={{
                                track: {
                                  width: "100%",
                                },
                                active: {
                                  backgroundColor: "#0040ff",
                                },
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-4 tool-results">
                    <div>
                      <Heading as="h4" size="sm">
                        Yield
                      </Heading>
                      <Heading as="h5" size="xl">
                        {toPercentage(grossYield, 1)}{" "}
                        <span className="unit-text">
                          gross
                          <InfoTooltip
                            id="grossYield"
                            message="Calculated as rent for the year divided by the property purchase price."
                          />
                        </span>
                      </Heading>
                      <Heading as="h5" size="xl">
                        {toPercentage(netYield, 1)}{" "}
                        <span className="unit-text">
                          net
                          <InfoTooltip
                            id="netYield"
                            message="Calculated as rent for the year less operating expenses (excluding mortgage repayments) divided by property purchase price."
                          />
                        </span>
                      </Heading>
                    </div>
                    <div>
                      <Heading as="h4" size="sm">
                        Cashflow
                        <InfoTooltip
                          id="cashflow"
                          message="Calculated as rent for the year less operating expenses and mortgage payments."
                        />
                      </Heading>
                      <Heading as="h5" size="xl">
                        {toCurrency(cashflow, 0)}{" "}
                        <span className="unit-text">/yr</span>
                      </Heading>
                    </div>
                    <div>
                      <Heading as="h4" size="sm">
                        Income
                        <InfoTooltip
                          id="income"
                          message="Calculated as rent for the year less operating expenses and interest."
                        />
                      </Heading>
                      <Heading as="h5" size="xl">
                        {toCurrency(income, 0)}{" "}
                        <span className="unit-text">/yr</span>
                      </Heading>
                    </div>
                    <div>
                      <Heading as="h4" size="sm">
                        Tax
                        <InfoTooltip
                          id="tax"
                          message="Tax payable if positive or benefit if negative. Calculated as marginal tax multiplied by the income."
                        />
                      </Heading>
                      <Heading as="h5" size="xl">
                        {toCurrency(tax, 0)}{" "}
                        <span className="unit-text">/yr</span>
                      </Heading>
                    </div>
                    <div className="bottom-text">
                      <span>
                        Based on 30 year principle and interest mortgage
                      </span>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RentalYieldCalculator
