export const NUM_MONTHS_IN_YEAR = 12
export const NUM_WEEKS_IN_YEAR = 52

const NUM_YEARS = 30

export const PMT = (rate, nper, pv) => {
  let fv = 0
  let type = 0
  if (rate === 0) return -(pv + fv) / nper
  var pvif = Math.pow(1 + rate, nper)
  var pmt = (rate / (pvif - 1)) * -(pv * pvif + fv)
  if (type === 1) {
    pmt /= 1 + rate
  }
  return pmt
}

export const IPMT = (rate, per, nper, pv) => {
  let pmt = PMT(rate, nper, pv)
  var tmp = Math.pow(1 + rate, per)
  return 0 - (pv * tmp * rate + pmt * (tmp - 1))
}

export const calcIncome = (rate, nper, pv) => {
  let sum = 0
  for (let i = 0; i < NUM_MONTHS_IN_YEAR; i++) {
    sum += IPMT(rate, i, nper, pv)
  }
  return sum
}

export const NPER = NUM_YEARS * NUM_MONTHS_IN_YEAR
